<template>
  <div
    class="fixed inset-y-0 backdrop-blur-2.5 right-0 flex bg-gray-900 flex-col shadow-lg bg-opacity-20 w-80"
  >
    <div class="text-right px-4 py-2">
      <button-close class="text-white" @click="$emit('close')" />
    </div>
    <div class="flex-1 max-h-full p-2 overflow-hidden hover:overflow-y-scroll">
      <slot />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import ButtonClose from '@/components/base/buttons/ButtonClose.vue'
export default defineComponent({
  components: {
    ButtonClose,
  },
  emits: ['close'],
})
</script>
